@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.horizontal-flex .space {
  flex-grow: 1;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffcc;
}

.App-logo {
  height: 7vmin;
  min-height: 2em;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  align-items: center;
  font-size: calc(10px + 1.4vmin);
  color: white;
  flex-grow: 0;
  padding: 0 1em;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#body {
  background-color: whitesmoke;
  flex-grow: 1;
  margin-bottom: 4vh;
  padding: 1.4em 2em;
  align-self: center;
}

@media only screen and (min-width: 800px) {
  #body {
    width: 75vw;
  }
}

#header-menu {
  display: flex;
  margin-right: 5vw;
}

#header-menu > li {
  margin: 0 1em;
  list-style-type: none;
}

#header-menu > li > a {
  text-decoration: none;
  color: white;
  font-size: calc(10px + 1.2vmin);
}

#header-menu > li > a:hover {
  color: #bbd;
}
.article-box {
    flex-grow: 1;
    color: black;
    display: block;
    text-decoration: none;
    cursor: pointer;
}

.article-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.article-title {
    font-size: 1.3em;
    font-family: 'Poppins', sans-serif;
    padding: 0 .5em 0 0;
}

.article-content {
    margin: .3em 0;
    text-align: left;
    font-size: 1em;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-content.with-img {
    -webkit-line-clamp: 4;
}

.article-author {
    font-style: italic;
    font-size: 0.8em;
    color: #666;
}

.article-date {
    color: #888;
    padding-right: 1em;
    margin-bottom: .5em;
}

.article-image {
    max-width: 12vw;
    max-height: 25vh;
    margin: 0 1vw;
    margin-bottom: .5%;
}

.name-content-img {
    display: flex;
}

.name-content {
    flex-grow: 1
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
}

.tag {
    border: solid 2px darkslategray;
    border-radius: 10px;
    padding: 2px 5px;
    margin: 1px 3px;
    font-size: small;
    color: whitesmoke;
    background-color: #7d879b;
    min-width: 20px;
    display: inline-block;
}

div.skeleton {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #e6e6e6 0%, #f2f2f2 50%, #e6e6e6 100%);
    background-size: 400% 400%;
    -webkit-animation: pulse 1.4s ease-in-out infinite;
            animation: pulse 1.4s ease-in-out infinite;
}

div.skeleton.line {
    width: 5.5em;
    border-radius: 5px;
}
div.skeleton.line::before {
    content: "\00a0";
}

@-webkit-keyframes pulse {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: -135% 0%;
    }
}

@keyframes pulse {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: -135% 0%;
    }
}
.vote-zone {
    margin-right: 1em;
}

.upvote-btn {
    rotate: 180deg;
}

.vote-counter {
    margin: -6px 0
}

.vote-btn {
    cursor: pointer;
    color: #687074;
}

.vote-btn.on{
    color: #33cc33;
}
#feed-header {
  display: flex;
  align-items: center;
}

#feed-header h1 {
  margin-left: 2%;
}

#feed {
  margin: 1em;
}

#feed > div {
  margin: 1em 0;
}

#sort-zone select {
  border: 1px solid gray;
  padding: 3px;
  border-radius: 5px;
  margin-left: .3em;
}

.flex-space {
  flex-grow: 1;
}

hr.article-sep {
  border: 1px solid #ccd;
}

.article {
  border-radius: 15px;
  padding: 5px 2px;
}
.article:hover {
  background-color: #e6e6e6;
}
.form-row {
    display: flex;
}

.form-row .form-group {
    flex: 0 0 50%;
    max-width: 50%;
}

.form-group {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;
    line-height: 1.5;
    text-align: left;
}

.form-group label {
    display: inline-block;
    margin-bottom: .5rem;
    font-weight: bolder;
    padding-left: .5em;
}

.form-group input,
.form-group textarea {
    display: block;
    padding: .3rem .8rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    resize: vertical;
}

@media only screen and (min-width: 800px) {
    #page {
      margin: 0 5vw;
    }
  }
#article-page {
    display: flex;
    flex-direction: column;
}

#article-page #info-box {
    display: flex;
    flex-direction: row;
    float: right;
    position: -webkit-sticky;
    position: sticky;
    border: solid;
    border-radius: 15px;
    padding: 1em;
    margin: 1em 0 0 0;
    grid-row: 1 / 3;
    grid-column: 2;
}

#article-page #info-box img {
    max-width: 100%;
    max-height: 20em;
}
#article-page #info-box #img {
    margin-right: 1em;
}

#article-page #title {
    grid-row: 1;
    grid-column: 1;
}

#article-page #content {
    grid-row: 2;
    grid-column: 1;
}

#article-page #info-box hr {
    margin: 1em 0;
}

#article-page #content {
    text-align: justify;
    margin-top: 1em;
    white-space: pre-line;
}

.hide-on-mobile {
    display: none;
}

@media only screen and (min-width: 950px) {
    #article-page {
        margin: 0 5vw;
        display: grid;
        grid-auto-columns: 2fr .8fr;
        grid-column-gap: 3em;
        -webkit-column-gap: 3em;
                column-gap: 3em;
    }

    #article-page #info-box {
        flex-direction: column;
        margin-right: -3em;
    }

    #article-page #info-box img {
        max-width: 100%;
        max-height: 200em;
    }

    #article-page #info-box #img {
        margin-right: 0;
    }

    #article-page #info-box hr {
        display: block;
    }

    #article-page #content {
        margin-top: 0;
    }

    .hide-on-mobile {
        display: unset;
    }
    
}
