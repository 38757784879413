.article-box {
    flex-grow: 1;
    color: black;
    display: block;
    text-decoration: none;
    cursor: pointer;
}

.article-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.article-title {
    font-size: 1.3em;
    font-family: 'Poppins', sans-serif;
    padding: 0 .5em 0 0;
}

.article-content {
    margin: .3em 0;
    text-align: left;
    font-size: 1em;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-content.with-img {
    -webkit-line-clamp: 4;
}

.article-author {
    font-style: italic;
    font-size: 0.8em;
    color: #666;
}

.article-date {
    color: #888;
    padding-right: 1em;
    margin-bottom: .5em;
}

.article-image {
    max-width: 12vw;
    max-height: 25vh;
    margin: 0 1vw;
    margin-bottom: .5%;
}

.name-content-img {
    display: flex;
}

.name-content {
    flex-grow: 1
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
}

.tag {
    border: solid 2px darkslategray;
    border-radius: 10px;
    padding: 2px 5px;
    margin: 1px 3px;
    font-size: small;
    color: whitesmoke;
    background-color: #7d879b;
    min-width: 20px;
    display: inline-block;
}
