.form-row {
    display: flex;
}

.form-row .form-group {
    flex: 0 0 50%;
    max-width: 50%;
}

.form-group {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;
    line-height: 1.5;
    text-align: left;
}

.form-group label {
    display: inline-block;
    margin-bottom: .5rem;
    font-weight: bolder;
    padding-left: .5em;
}

.form-group input,
.form-group textarea {
    display: block;
    padding: .3rem .8rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    resize: vertical;
}

@media only screen and (min-width: 800px) {
    #page {
      margin: 0 5vw;
    }
  }