#feed-header {
  display: flex;
  align-items: center;
}

#feed-header h1 {
  margin-left: 2%;
}

#feed {
  margin: 1em;
}

#feed > div {
  margin: 1em 0;
}

#sort-zone select {
  border: 1px solid gray;
  padding: 3px;
  border-radius: 5px;
  margin-left: .3em;
}

.flex-space {
  flex-grow: 1;
}

hr.article-sep {
  border: 1px solid #ccd;
}

.article {
  border-radius: 15px;
  padding: 5px 2px;
}
.article:hover {
  background-color: #e6e6e6;
}