div.skeleton {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #e6e6e6 0%, #f2f2f2 50%, #e6e6e6 100%);
    background-size: 400% 400%;
    animation: pulse 1.4s ease-in-out infinite;
}

div.skeleton.line {
    width: 5.5em;
    border-radius: 5px;
}
div.skeleton.line::before {
    content: "\00a0";
}

@keyframes pulse {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: -135% 0%;
    }
}