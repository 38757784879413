.vote-zone {
    margin-right: 1em;
}

.upvote-btn {
    rotate: 180deg;
}

.vote-counter {
    margin: -6px 0
}

.vote-btn {
    cursor: pointer;
    color: #687074;
}

.vote-btn.on{
    color: #33cc33;
}