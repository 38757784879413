#article-page {
    display: flex;
    flex-direction: column;
}

#article-page #info-box {
    display: flex;
    flex-direction: row;
    float: right;
    position: sticky;
    border: solid;
    border-radius: 15px;
    padding: 1em;
    margin: 1em 0 0 0;
    grid-row: 1 / 3;
    grid-column: 2;
}

#article-page #info-box img {
    max-width: 100%;
    max-height: 20em;
}
#article-page #info-box #img {
    margin-right: 1em;
}

#article-page #title {
    grid-row: 1;
    grid-column: 1;
}

#article-page #content {
    grid-row: 2;
    grid-column: 1;
}

#article-page #info-box hr {
    margin: 1em 0;
}

#article-page #content {
    text-align: justify;
    margin-top: 1em;
    white-space: pre-line;
}

.hide-on-mobile {
    display: none;
}

@media only screen and (min-width: 950px) {
    #article-page {
        margin: 0 5vw;
        display: grid;
        grid-auto-columns: 2fr .8fr;
        column-gap: 3em;
    }

    #article-page #info-box {
        flex-direction: column;
        margin-right: -3em;
    }

    #article-page #info-box img {
        max-width: 100%;
        max-height: 200em;
    }

    #article-page #info-box #img {
        margin-right: 0;
    }

    #article-page #info-box hr {
        display: block;
    }

    #article-page #content {
        margin-top: 0;
    }

    .hide-on-mobile {
        display: unset;
    }
    
}