.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffcc;
}

.App-logo {
  height: 7vmin;
  min-height: 2em;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  align-items: center;
  font-size: calc(10px + 1.4vmin);
  color: white;
  flex-grow: 0;
  padding: 0 1em;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#body {
  background-color: whitesmoke;
  flex-grow: 1;
  margin-bottom: 4vh;
  padding: 1.4em 2em;
  align-self: center;
}

@media only screen and (min-width: 800px) {
  #body {
    width: 75vw;
  }
}

#header-menu {
  display: flex;
  margin-right: 5vw;
}

#header-menu > li {
  margin: 0 1em;
  list-style-type: none;
}

#header-menu > li > a {
  text-decoration: none;
  color: white;
  font-size: calc(10px + 1.2vmin);
}

#header-menu > li > a:hover {
  color: #bbd;
}